
.agent-code-book{
    margin-left: 17%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.agent-code-book-sub{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid black;
}
.agent-code-book-sub-select-div{
    display: flex;
    justify-content: space-around;
    width: 800px;
}

.agent-code-book-agent-div{
    background-color: rgba(61, 61, 61, 0.193);
    border: 1px solid black;
    border-radius: 10px;
    padding: 20px;
    margin: 10px
}
.agent-code-book-agent-div-results-div{
    background-color: rgba(179, 176, 176, 0.472);
    display: flex;
    flex-direction: column;
    height: 120px;
    overflow-y:scroll ;
    margin-top: 5px;
}
.agent-code-book-select-result{

}

.agent-code-book-date-div{
    display: flex;
    gap: 50px;
    background-color: rgba(150, 150, 150, 0.471);
    margin: 10px;
    padding: 10px 20px;
}





.agent-code-book-btn-div{
    width: max-content;
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: rgb(183, 183, 183);
    padding: 3px 10px;
}
.agent-code-book-btn{
    background-color: rgb(20, 134, 96);
    padding: 10px 20px;
    border: none;
    font-weight: 600;
}

.agent-code-book-table{
 
    table-layout: fixed;
    border-right: 1px solid black;
}
.agent-code-book-header{
    background-color: aqua;
}



.agent-code-book-table-body{
    border-left: 1px solid black;
    td{
        text-align: center;
    
    }
    
}