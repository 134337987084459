
.TCPriview{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.TCPriview-sub{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.TCPriview-hero-title{
    font-size: 60px;
    font-weight: 500;
    font-family: Playfair Display;
    color: white;
    margin-top: 150px;
}
.TCPriview-hero-route-div{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: -50px;
    margin-bottom: 50px;
}
.TCPriview-hero-route{
    font-size: 18px;
    font-weight:500;
    font-family: Poppins;
    color: white;
    text-decoration: none;
}
.TCPriview-hero-route-active{
    font-size: 18px;
    font-weight:500;
    font-family: Poppins;
    color: orange;
    cursor: default;
}



.TCPriview-description{
    text-align: justify;
    margin: 30px 0px;
    font-size: 16px;
    font-weight: 300;
    font-family: poppins;
    width: 1200px;
    /* background-color: yellowgreen; */
}

.TCPriview-card_div{
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: left;
    margin-bottom: 10px;

    /* background-color: aqua; */

}