.dashboard-tour{
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 220px;
}
.dashboard-tour-underline{
    border-bottom: 2px solid black;
    width: 1000px;
}
.dashboard-tour-sub{
    display: flex;
    flex-direction: column;
    /* background-color: blue; */
    align-items: center;
    width: 1200px;
    border: 1px solid black;
    margin: 20px 1px;
}
.tour-package-main-1{
    /* background-color: antiquewhite; */
    margin-top: 50px;
    width: max-content;
    border: 1px solid black;
    padding: 10px 20px;

}
.dashboard-tour-form{
    display: flex;
    justify-content: space-between;
    width: 600px;
    margin: 10px 5px;
    
}




.tour-package-main-2{
    /* background-color: aqua; */
    border: 1px solid black;
    padding: 10px 20px;
    

    display: flex;
    margin: 50px;
}
.tour-package-days-div{
    width: 200px;
    height: 100%;
    background-color: rgb(186, 186, 186);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tour-package-place-div{
    width:900px;
    height: 100%;
    /* background-color: rgb(91, 78, 78); */
}
.tour-places-day-description-div{
    display: flex;
    justify-content: center;
    gap: 10px;

    margin-bottom: 10px;
}
.tour-places-day-description-input{
    width: 400px;
    height: 120px;
}


.tour-places-hotel-title{
    text-align: center;
}
.tour-places-hotel-div{
    display: flex;
    justify-content: space-around;
}
.tour-places-hotel-select{
    width: 150px;
    height: 25px;
    margin-left: 5px;
}


















.tour-places-header2{
    text-align: center;
}
.tour-package-search-div{
    display: flex;
    justify-content: space-around;
    /* background-color: aqua; */
}
.tour-package-place-search-div{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tour-package-place-results{
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-left: 50px;
    border: 1px solid black;
    max-height: 200px;
    min-height: 200px;
    overflow-y: scroll;
    width: 300px;

}
.tour-package-place-result{
    color: rgb(20, 43, 43);
    background-color: yellowgreen;
    margin: 1px 0px;
}





.day-active{

    padding: 10px 0px;

    font-size: 20px;
    background-color: rgb(115, 115, 115);
    width: 98%;
    margin: 1px 10px;
    text-align: center;
}
.day{
    margin: 5px auto;
    padding: 10px 0px;

    font-size: 20px;
    width: 100%;
    text-align: center;
}




.day-tour-place-div{
    margin: 2px;
}
.day-tour-place-div-sub1{
    display: flex;
    align-items: center;
    gap: 10px;
}
.day-tour-place-div-p{
    font-weight: 600;
}
.place-delete-img{
    width: 25px;
}
.day-tour-place-div-input{
    width: 600px;
    height: 100px;

}


.tour-btn{
    background-color:var(--blue);
    padding: 10px 30px;
    border-radius: 20px;
    color: aliceblue;
    position: relative;
    left: 40%;
    margin: 20px;
    
}