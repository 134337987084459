
.request{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-left: 5%;
    
}


.request-div{
    border: 1px solid black;
    padding: 20px;
    max-height: 800px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.request-date-div{
    display: flex;
    gap: 50px;

}





.request-table{
    padding: 20px;
 
    table-layout: fixed;
}
.request-table-header{
    background-color: aqua;
}



.request-table-body{
    text-align: center;
}

.request-table-btn-div{
    width: max-content;
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: rgb(183, 183, 183);
    padding: 3px 10px;
}
.request-table-btn{
    background-color: rgb(20, 134, 96);
    padding: 10px 20px;
    border: none;
    font-weight: 600;
}