.register-main{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100vw;
    height: 100vh;
    background-size: cover;
    background-image: url('./../../../assets/sunset.png')
}
.register{
    display: flex;
    background-color: rgba(0, 0, 0, 0.489);
    padding: 10px 50px;
    border-radius: 20px;

}


.register-right-div{

    /* background-color: aquamarine; */

    display: flex;
    justify-content: center;
    align-items: center;

}
.register-right-img{
    width: 500px;
    height: 500px;
}





.register-center-div{
    display: flex;
    align-items: center;
    justify-content: right;
    width: 50px;
}
.register-center-img{
        height: 500px;
}






.register-left-div{
    /* background-color: rgba(250, 235, 215, 0.3); */
}

.register-form-div{
    /* background-color: yellowgreen; */
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;

}
.register-header-text-div{
    /* background-color: rgb(170, 118, 49); */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -1px;
    margin-bottom: 10px;

}
.register-header-text{
    font-size: 35px;
    font-weight: 500;
    font-family: poppins;
    color: white;
    margin-top: -5px;
    margin-bottom: 0px;
    }
.register-header-sub-text{
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    font-family: poppins;
    text-align: center;
    color: white;
    margin-top: -5px;
    margin-bottom: 1px;
}

.register-form{
    display: flex;
    flex-direction: column;
    width:100%;
    /* background-color: blueviolet; */
    margin: 8px auto;
}
.register-label{
    font-size: 18px;
    font-weight: 400;
    font-family: poppins;
    color: white;
}
.register-form-sub{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.register-input{
    height: 25px;
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 3px solid rgba(185, 185, 186, 1);
    color: white;
}

.register-input-validate-p{
    font-size: 10px;
    color: red;
    margin-top: 0px;
    margin-bottom: 0px;
}
.register-validate-img{
    width: 20px;
}

.register-form-checkbox{
    margin: 10px 0px 10px 0px;
    display: flex;
    align-items: center;
}
.register-label-checkbox{
    font-size: 11px;
    font-weight: 400;
    font-family: poppins;
    color: white;
    
}
.register-link{
    color: orange;
    font-size: 11px;
    font-weight: 600;
    font-family: Lato;
}
.register-btn{
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 600;
    font-family: poppins;
    color: rgb(0, 0, 0);
    background-color: orange;
    margin-top: 20px;

}
.register-or{
    font-size: 20px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    margin: 10px auto;
}
.google-icon-register{
    width: 30px;
}
.register-bottom-text{
    font-size: 15px;
    font-weight: 400;
    color: white;
}
.register-login{
    color: orange;
    font-weight: 500;
    text-decoration: none;
}