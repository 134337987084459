.App {
  background-color:rgb(255, 255, 255);

}



.Add-sub-div{
  margin-left: 17%;

}


:root{




  --font_1:'popins';
  --font_2:'';

  --orange:rgb(243,149,57);
  --blue:rgb(8, 28, 85);
  --blue1:#0a2e84;






}


.dashboad-add-btn{
  background-color: var(--color_1);
  padding: 10px 30px;
  border: none;
  border-radius: 25px;
  font-size: 22px;
  color: aliceblue;
  text-transform: uppercase;
}
.readme-btn{
  background-color: orange;
  padding: 20px 30px;
  border-radius: 25px;
  margin: 5px;
  color: aliceblue;
  text-decoration: none;

}