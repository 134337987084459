.homecarousel-main-div{

    display: flex;

    width: 100%;
    margin-top: 40px;
}
.homecarousel-sub-div1{
    background-color: rgb(8, 28, 85);
    width: 45%;
    height: 500px;
}
.homecarousel-sub-div2{
    background-color: rgb(255, 255, 255);
    width: 55%;
    height: 500px;
}
.homecarousel-upper-div{
    position: absolute;
    /* background-color: rgba(153, 205, 50, 0.345); */
    width: 100%;
    height: 500px;

    display: flex;
    justify-content: center;
    align-items: center;
}






.homecarousel{
    padding: 50px 0px;

    width: 765px;

    /* background-color: yellowgreen; */
    

}
.homecarousel-info-div{
    /* background-color: antiquewhite; */
    height: 400px;
    width: 330px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.homecarousel-info-p1{
    width: 300px;
    font-size: 45px;
    font-weight: 500;
    color: aliceblue;
    margin-top: -20px;
    font-family: poppins;
}
.homecarousel-info-p2{
    font-size: 14px;
    font-weight: 300;
    width: 300px;
    color: aliceblue;
    font-family: poppins;
    line-height: 21px;

}
.homecarousel-info-btn{
    position: absolute;
    bottom: 60px;
    background-color: var(--orange);
    color: rgb(0, 0, 0);
    padding: 10px 40px;
    border: none;
    border-radius: 25px;
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    font-family: poppins;
}








.carousel{

    /* background-color: yellowgreen; */
}
.carousel-container{
    /* background-color: aqua; */
    display: flex;
    justify-content: space-between;
    gap: 100px;
}





.homecarousel-forward-arrow{
    background-color: rgba(87, 87, 87, 0.445);
    width: 48px;
    height: 48px;
    border-radius: 100px;
    position: absolute;
    top: 150px;
    left: -30px;




}

.homecarousel-backward-arrow{
    background-color: rgba(87, 87, 87, 0.445);
    width: 48px;
    height: 48px;
    border-radius: 100px;
    position: absolute;
    z-index: 2;
    top: 150px;
    right: -30px;



}















