
.hotels-main{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 100px;

}
.hotels-main-div{
    border: 1px solid black;
    border-radius: 10px;
    padding: 50px;
    margin: 50px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.hotels-form-div{
    display: flex;
    flex-direction: column;
    width: 600px;
    margin: 10px auto;

}
.hotel-prices-form-div-delete-btn{
    background-color: red;
    color: white;
    padding: 10px 20px;
}
.hotels-form-label{
    font-size: 24px;
}
.hotels-form-input{
    height: 30px;
}




.hotel-price-main{
    border: 1px solid black;
    padding: 20px;
    margin: 5px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 50px;
}
.hotel-prices-form-div{
    display: flex;
    flex-direction: column;
}
.hotel-prices-dashboad-add-btn{
    font-size: 30px;
    line-height: 10px;
    font-weight: 700;
    width: 30px;
    height: 30px;
    cursor: pointer;


}
.hotel-dashboad-add-btn{
    background-color: blue;
    padding: 10px 30px;
    border: none;
    border-radius: 20px;
    color: white;
}