
.profile{
margin-left: 10px;

}
    .profile-title{
        font-size: 24px;
    font-weight: 400;
    font-family: poppins;
    }
.profile-line{
    border-bottom: 3px solid rgba(185, 185, 186, 1);
    width: 90%;
}

.profile-form-div{
    width: 500px;
    margin-top: 50px;
    margin-bottom: 100px;
}
.profile-img-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
}
.img-container{
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background-color: rgb(209, 209, 209);
}
.img-edite-btn{
    margin: 10px;
    background-color: var(--blue);
    padding: 6px 20px;
    border: none;
    color: white;
    border-radius: 7px;
}

.profile-form{
    display: flex;
    flex-direction: column;
    margin: 10px 20px;
}
.profile-form-label{
    font-size: 20px;
    font-weight: 400;
    font-family: poppins;
}
.profile-form-input{
    height: 35px;
    border: 2px solid rgb(145, 145, 145);
    border-radius: 5px;
}
.profile-update-btn{
    background-color: var(--orange);
    padding: 7px 20px;
    border-radius: 10px;
    margin-left: 20px;
    top: 30px;
    position: relative;
    border: none;
    color: white;
    font-size: 17px;
}