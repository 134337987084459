
.tc-card{
    background-color: rgb(235, 235, 235);
    width: 294px;
    height: 376px;
    display: flex;
    flex-direction: column;
    align-items: end;
    overflow: hidden;
    &:hover{
        .tc-card-img{
            transform:scale(1.1);
                -ms-transform:scale(1.1);
                transition: .4s;
       }
        .tc-card-a{
            background-color: var(--blue);
            color: white;
            transition: .7s;
            font-weight: 700;

        }
    }
}
.tc-card-img{
    width: 294px;
    height: 376px;
    transition: .4s;
}
.tc-card-info-div{
    background-color: rgba(0, 0, 0, 0.496);
    width: 100%;
    height: 156px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -156px;
    position: relative;

}
.tc-card-header{
    font-size: 20px;
    font-weight: 500;
    font-family: latio;
    color: azure;
    text-align: center;
    width: 290px;
    height: 22px;
    overflow: hidden;


    margin-top: 1px;
    /* background-color: yellowgreen; */
    display: flex;
    align-items: center;
    justify-content: center;

}
.tc-card-p1{
    font-size: 14px;
    font-weight: 400;
    color: white;
    width: 90%;
    height: 22px;
    text-align: center;
    /* background-color: aqua; */
    margin-top: -18px;
    overflow: hidden;

    /* display: flex;
    align-items: center;
    justify-content: center; */

}
.tc-card-p2{
    color: azure;
    font-size: 13px;
    font-weight: 400;
    text-align: justify;
    width: 290px;
    height: 70px;
    line-height: 13px;
    overflow: hidden;
    /* background-color: violet; */
    margin-top: -14px;

}
.tc-card-a{
    text-align: center;
 color: rgb(7, 7, 7);
 background-color: orange;
 padding: 3px 15px;
 border-radius: 25px;
 font-size: 12px;
 font-weight: 500;
 font-family: poppins;
 text-decoration: none;
 margin-top: -5px;
 transition: .8s;


}