
.Places{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.wrapper{
    width: 100%;
    height: 528px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Places-main-title{
    font-size: 60px;
    font-weight: 600;
    font-family: Playfair Display;
    color: white;
    margin-top: 150px;
}
.Places-main-route{
    display: flex;
    align-items: center;
    gap: 7px;
    margin-top: -50px;
}
.Places-main-route-link{
    font-size: 18px;
    font-weight:500;
    font-family: Poppins;
    color: white;
    text-decoration: none;
}
.Places-main-route_link{
    font-size: 18px;
    font-weight:500;
    font-family: Poppins;
    color: orange;
    text-decoration: none;
}


.Places-main-media{
    display: flex;
    gap: 20px;
    margin-top: 40px

}




.Places-search-div{
    /* background-color: aqua; */
    width: 1200px;
    display: flex;
    justify-content: center;
    margin: 30px auto;
}
.Places-search-div-input{
    width: 252px;
    height: 37px;
    border: 2px solid rgba(83, 83, 83, 0.471);
    border-radius: 25px;
    text-align: center;
}
.Places-filter{
    width: 252px;
    height: 37px;
    border-radius: 10px;
    border: 2px solid rgb(150, 150, 150);
    margin-left: 20px;
    text-align: center;
}



.Places-pagination{
    margin: 30px auto;
}
.Places-pagination-btn{
    background-color: transparent;
    font-size: 20px;
    border: none;
    font-weight: 600;
    color: black;
}

.Places-places{
    width: 1200px;
    display: flex;
    justify-content:left;
    flex-wrap: wrap;
    gap: 5px;
    /* background-color: aqua; */
}

.Places-pagination{
    background-color: rgba(0, 0, 0, 0.052);
    max-width: 450px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    
}
.Places-pagination-btn{
    background-color: blue;
}
.Places-pagination-btn1{
    background-color: rgb(255, 0, 81);
    font-size: 30px;
    border: none;
    background: transparent;
}
.Places-pagination-btn2{
    font-weight: 400;
    font-size: 22px;

    margin: auto 5px;
    background: transparent;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

}
.Places-pagination-p2{
    font-weight: 400;
    font-size: 22px;

    margin: auto 7px 6px 7px;
    border: 1px solid black;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
}
.Places-pagination-btn3{
    font-size: 30px;
    border: none;
    background: transparent;
    /* background-color: rgb(0, 255, 17); */
}
.Places-pagination-more{
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 3px;
}
