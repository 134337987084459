
.setting{
height: 100%;
margin-left: 10px;
}
.setting-title{
    font-size: 24px;
    font-weight: 400;
    font-family: poppins;
}
.setting-line{
    border-bottom: 3px solid rgba(185, 185, 186, 1);
    width: 90%;
}
.setting-form-div{
    width: 500px;
    margin: 50px;

}
.setting-form{
    display: flex;
    flex-direction: column;
    margin:20px;
}
.setting-form-label{
    font-size: 20px;
    font-weight: 400;
    font-family: poppins;
}
.setting-form-input{
    height: 35px;
    border: 2px solid rgb(164, 164, 164);
}

.setting-update-btn{
    background-color: var(--orange);
    padding: 7px 20px;
    border-radius: 10px;
    margin-left: 20px;
    top: 30px;
    position: relative;
    border: none;
    color: white;
    font-size: 17px;

}