
.dashboad-description{
    margin-left: 280px;
}

.dashboad-description-h1{
    text-align: center;
}
.dashboad-description-line{}


.dashboad-description-1{
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin: 20px;
}
.dashboad-description-1-h2{

}
.dashboad-description-1-input{
    width: 800px;
    height: 150px;
}

.dashboad-description-btn{
    background-color: blue;
    color: white;
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    margin: 5px auto;
}