.add-place{
    margin-left: 1px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.add-place-sub{
    
    /* background-color: yellow; */
    margin-left: 150px;
    /* background-color: aqua; */

}
.header-Add_place{
    text-align: center;
    /* background-color: aqua; */
}
.Add_place-line{
    border-bottom: 2px solid black;
    margin: 0px 20px;
    margin-top: 0px;
}
.parent-Add_place {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    margin-bottom: 50px;
    padding-bottom: 200px;
    /* background-color: aqua; */
    }
    
.div1-Add_place { 
        grid-area: 1 / 1 / 2 / 2;

        height: 70vh;
        padding-top: 0px;
        padding-left: 0px;
        height: 100%;
     }
.div2-Add_place { grid-area: 1 / 2 / 2 / 3;

                padding-top: 0px; 
                display: flex;
                justify-content: center;
                align-items: center;
                /* background-color: blue; */
                height: 640px;
            }


.form-div-Add_place{
    /* background-color: aliceblue; */
    margin: 10px 20px;


}
.Add_place-form-sub-div{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 20px;
}
.Add_place-form-location-div{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0px 0px;
    
}
.Add_place-form-sub-div-label{
    font-size: 21px;
    font-weight: 400;
    line-height: 24px;
}
.add_place_input{
    height: 30px;
    background-color: rgba(255, 255, 255, 0.959);  
    border: 2px solid rgb(149, 149, 149);
    border-radius: 10px;
    text-align: center;
    font-weight: 600;

}
.add_place_input-description{
    height: 100px;
    background-color: rgba(255, 255, 255, 0.959);  
    border: 2px solid rgb(149, 149, 149);
    border-radius: 10px;
    text-align: center;
    font-weight: 600;

}
.add_place_input-location{
    height: 30px;
    background-color: rgba(255, 255, 255, 0.959);
    border: 2px solid rgb(149, 149, 149);
    border-radius: 10px;
    
}
.Add_place-form-sub-div-button{
    width: 144px;
    height: 48px;
    border: none;
    background-color:rgb(2, 31, 103);
    margin-top: 20px;
    border-radius: 25px;
    color: aliceblue;
    font-size: 18px;
    right: -74%;
    position: relative;
}
  input[type=file]::file-selector-button {
    margin-right: 20px;
    border: none;
    background: #ffffff;
    padding: 6px 10px;
    border-radius: 10px;
    color: #040404;
    cursor: pointer;
    transition: background .2s ease-in-out;
}
.Add_place-img-list-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 400px;
}
.Add_place-img-list-delete-img{
    width: 30px;
}



.Add_place-map-div{
    background-color: rgb(116, 116, 116);
    width: 500px;
    height: 500px;
    
}
.map-container1{
    width: 100%;
    height: 100%;
}