
.dashboad-tourbook{
    margin-left: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.dashboad-tourbook-sub{
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid black;
}
.dashboad-tourbook-date-div{
    display: flex;
    gap: 50px;
}


.request-table-btn-div{
    width: max-content;
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: rgb(183, 183, 183);
    padding: 3px 10px;
}
.request-table-btn{
    background-color: rgb(20, 134, 96);
    padding: 10px 20px;
    border: none;
    font-weight: 600;
}