.login-main{
    display: flex;
    justify-content: center;
    align-items: center;
    width:100vw;
    height: 100vh;
    background-size: cover;
    background-image: url('./../../../assets/sunset.png')
    

}
.login{

    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.489);
    padding: 50px;
    border-radius: 20px;
    
}
.login-left-div{
    width: 47%;
    display: flex;
    justify-content: center;
    align-items: center; 
}
.login-left-img{
    width: 500px;
}




.login-center-div{}
.login-center-img{
    height: 500px;
}







.login-right-div{
    width: 47%;
    /* background-color: aquamarine; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.login-header-text-div{
    /* background-color: antiquewhite; */
    width: 100%;
}
.login-header-text{
    font-size: 35px;
    font-weight: 500;
    font-family: poppins;
    text-align: center;
    color: white;
}
.login-form-div{
    /* background-color: yellowgreen; */
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
}



.login-form{
    display: flex;
    flex-direction: column;
    width: 100%;
    
    /* background-color: blueviolet; */
    margin: 8px auto;
}

.login-label{
    font-size: 18px;
    font-weight: 400;
    font-family: poppins;
    color: white;
}
.login-input{
    height: 25px;
    background-color: transparent;
    border: none;
    border-bottom: 3px solid rgba(185, 185, 186, 1);
    color: white;
    font-size: 20px;
    line-height: 1px;
    &:focus {
        border:0px solid red;
      }
}
.login-form-{
    margin: 10px 0px 10px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.login-error{
    font-size: 14px;
    color: red;
    font-weight: 600;
    margin-top: 1px;
}

.login-form-sub{
    display: flex;
    align-items: center;
    gap: 5px;
}
.login-form-checkbox{
    margin: 10px 0px 10px 0px;
    background-color: transparent;
    color: transparent;
}
.login-label-checkbox{
    font-size: 11px;
    font-weight: 400;
    font-family: poppins;
    color: white;
    
}
.forgot-password{
    color: orange;
    font-size: 11px;
    font-weight: 500;
    font-family: poppins;
    text-decoration: none;
}
.login-btn{
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 10px;
    font-size: 15px;
    font-weight: 500;
    font-family: poppins;
    color: rgb(0, 0, 0);
    background-color: orange;
    margin-top: 20px;

}
.login-or{
    font-size: 20px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    margin: 10px auto;
}
.google-icon-login{
    width: 30px;
}
.login-bottom-text{
    font-size: 15px;
    font-weight: 400;
    color: white;
}
.login-login{
    color: orange;
    font-weight: 500;
    text-decoration: none;
}