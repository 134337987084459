
.user{
    

    margin-top: 100px;
    display: flex;

}

.user-left{
    background-color: antiquewhite;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 500px;
}
.user-link-active{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 30px;
    border: none;
    border-left: 5px solid orange;
    width: 120px;
    margin: 2px 5px;
    color: orange;
    font-size: 20px;
    font-weight: 400;
}
.user-link{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px 30px;
    border: none;
    width: 120px;
    margin: 2px 5px;
    font-size: 20px;
    font-weight: 400;

}
.user-link-img{
    fill: transparent;
    stroke: rgb(0, 0, 0);
}



.user-right{
    width: 100%;
}




