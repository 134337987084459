
.add-team{
    margin-left: 270px;
}

.add-team-title-2{
    text-align: center
}



.add-team-add{
    margin: 10px 100px;
    border: 1px solid black;
    padding: 10px 100px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.add-team-add-left{
    /* background-color: aqua; */
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-around;

}
.add-team-add-right{
    /* background-color: yellowgreen; */
    width: 30%;
    
}
.add-team-add-form{
    display: flex;

    gap: 10px;
}
.add-team-add-img{
    width: 200px;
    height: 300px;
    border: 1px solid black;
}
.add-team-add-img-p{
    width: 200px;
    height: 300px;
    border: 1px solid black;
}
.add-team-add-btn{
    background-color: blue;
    padding: 5px 15px;
    border: none;
    color: white;
}












.add-team-view-main{
    display: flex;
    flex-direction: column;
}
.add-team-view{
    margin: 10px 100px;
    border: 1px solid black;
    padding: 10px 100px;
    display: flex;
}
.add-team-view-left{

    width: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}
.add-team-view-img{
    width: 200px;
    height: 300px;
    border: 1px solid black;
}

.add-team-view-btn-div{
    display: flex;
    gap: 10px;
    margin: 5px;
}
.add-team-view-btn{
    background-color: blue;
    padding: 5px 15px;
    border: none;
    color: white;
}
.add-team-view_btn{
    background-color: red;
    padding: 5px 15px;
    border: none;
    color: white;
}