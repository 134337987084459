.tour-edite{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tour-edite-title{
    font-size: 30px;
}
.tour-edite-line{
    border-bottom: 2px solid black;
    width: 90%;
}



.tour-edite-search-div{
    margin: 20px auto;
}
.tour-edite-search-input{
    width: 300px;
    height: 30px;
}
.tour-edite-search-result-div{

    display: flex;
    flex-direction: column;
    max-height: 130px;
    overflow-y: scroll;
    align-items: center;
    justify-content: center;
}
.tour-edite-search-result{
    background-color: rgb(237, 237, 237);
    margin: 1px;
    text-align: center;
    width: 90%;
    padding: 3px 1px;
}




.tour-edite-form-div-main{
    padding: 100px 50px;
    border: 1px solid black;
    margin-bottom: 20px;
}

.tour-edite-form{
    display: flex;
    flex-direction: column;
}
.tour-edite-form-label{
    font-size: 22px;
}
.tour-edite-form-input{
    width: 500px;
    height: 32px;
}
.tour-edite-form-img{
    width: 200px;
}




.tour-day-edite-form-div{
    display: flex;
    border: 1px solid black;
    padding: 10px;
    
}
.tour-day-edite-form-div-left{
    width: 200px;
    background-color: blanchedalmond;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}
.tour-day-edite-form-div-left-a-active{
    background-color: rgb(30, 61, 51);
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
}
.tour-day-edite-form-div-left-a{
    background-color: aquamarine;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
}
.tour-day-edite-form-div-right{
    width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tour-day-edite-form-div-right-form{
    display: flex;
    flex-direction: column;
}
.tour-day-edite-form-div-right-form-label{
    font-size: 22px;
}
.tour-day-edite-form-div-right-form-input{
    width: 500px;
    height: 100px;
}


.tour-day-edite-form-div-right-sub{
    border: 1px solid black;
    padding: 50px;
    margin: 10px;
}
.tour-day-edite-form-div-right-form-1{
    display: flex;
    flex-direction: column;
}
.tour-day-edite-form-div-right-form-label-1{
    font-size: 22px;
}
.tour-day-edite-form-div-right-form-input-1{
    width: 500px;
    height: 30px;
}



.touredite-update-day-place-select-div{
    padding: 20px;
    background-color: antiquewhite;
}


.touredite-update-btn{
    background-color: rgb(12, 12, 71);
    color: white;
    padding: 10px 30px;
    margin: 10px;
}

.touredite-delete-btn{
    background-color: rgb(255, 0, 0);
    color: white;
    padding: 10px 30px;
}