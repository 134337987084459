
.place-report{
    margin-left: 17%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.place-report-title{
    text-align: center;
}
.place-report-sub{
    margin: 10px;
    padding: 10px;
    border: 1px solid black;
    width: 80%;
}
.place-report-table{
    width: 100%;
    table-layout: auto;
}
.place-report-table-head{
    background-color: aqua;
}

.place-report-table-tr{
    text-align: center;
}



.place-report-btn-div{
    width: max-content;
    display: flex;
    align-items: center;
    gap: 5px;
    background-color: rgb(183, 183, 183);
    padding: 3px 10px;
}
.place-report-btn{
    background-color: rgb(20, 134, 96);
    padding: 10px 20px;
    border: none;
    font-weight: 600;
}