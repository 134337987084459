.traveler_experience{
    background-image: url('./../../assets//homeimg/travelerExperience.png');
    background-size: cover;
    border-bottom: 2px solid white;
    display: flex;
    justify-content: center;

    /* background-color: rgb(0,0,0);
    background:  linear-gradient(0deg, rgba(0,0,0,1) 10%, rgba(0, 0, 0, 0.912) 49%, rgba(40, 40, 40, 0.994) 100%); */
}
.traveler-experience{

    
    
    padding: 50px 10px 150px 10px;
    display: flex;
    justify-content: space-between;
    width: 1200px;
    
}
.travelerexperience-title{
    text-align: left;
    font-size: 45px;
    font-weight: 500;
    color: rgb(255, 255, 255);
    font-family: 'Poppins';
    
}
.traveler-experience-left{

}
.traveler-experience-right{
    margin-top: 150px;

    display: flex;
    flex-direction: column;
    align-items: center;
}
.traveler-experience-right-img{
    width: 149px;
}
.traveler-experience-right-p{
    width: 388px;
    color: aliceblue;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 300;
    text-align: justify;
}
.traveler_experience-readme{
    display: flex;
    justify-content: center;
    margin-top: -50px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    font-family: poppins;
    color: white;
    text-decoration: none;
}














.travelerexperience-container{
    /* background-color: yellowgreen; */
    width: 750px;
    height: 380px;
    display: flex;

}
.travelerexperience-container-left{
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: antiquewhite; */
    width: 100%;

}
.travelerexperience-img{
    width: 144px;
    height: 144px;
    margin-top: -100px;
}
.travelerexperience-container-right{


    /* background-color: rgb(155, 100, 18); */
    display: flex;
    flex-direction: column;
    padding-left: 20px;

    width: 500px;

}
.travelerexperience-container-right-text{
    font-size: 16px;
    font-weight: 300;
    font-family: 'Poppins';
    color: rgb(255, 255, 255);
    width: 500px;
    text-align: justify;
}
.travelerexperience-container-right-title{
    font-size: 28px;
    line-height: 1px;
    color: rgb(255, 255, 255);
    font-weight: 500;

}
.travelerexperience-container-right-country{
    font-family: Lato;
    color: white;
    font-size: 24px;
    font-weight: 300;
    margin-top: 5px;
}



.travelerexperience-backward-btn{
    position: absolute;
    margin-top: 400px;
    margin-left: 650px;
    padding: 10px 16px;
    border-radius: 50%;
    border: none;
    font-size: 20px;
    font-weight: 600;
    background-color: rgba(240, 248, 255, 0.345);
    color: white;
}
.travelerexperience-forward-btn{
    position: absolute;
    margin-top: 400px;
    margin-left: 550px;
    padding: 10px 16px;
    border-radius: 50%;
    border: none;
    font-size: 20px;
    font-weight: 600;
    background-color: rgba(240, 248, 255, 0.345);
    color: white;
}