.place-card{
    width: 294px;
    height: 376px;
    overflow: hidden;


    &:hover{
        .place-card-img{
            transform:scale(1.1);
                -ms-transform:scale(1.1);
                transition: .4s;
       }
        .place-card-info-more{
            background-color: var(--blue);
            color: white;
            transition: .7s;
            font-weight: 700;

        }
    }
}
.place-card-img{
    width: 100%;
    height: 100%;
    transition: .4s;

}
.place-card_info{
    background-color: rgba(0, 0, 0, 0.496);
    height: 156px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -160px;
    position: relative;

}
.place-card-info-title{
    font-size: 20px;
    font-weight: 500;
    font-family: latio;
    color: azure;
    text-align: center;
    width: 290px;
    max-height: 80px;
    overflow: hidden;
    margin-top: 7px;
    /* background-color: aqua; */
    line-height: 18px;
    padding-bottom: 6px;
   
}
.place-card-info-info{
    color: azure;
    font-size: 13px;
    font-weight: 400;
    text-align: justify;
    width: 270px;

    height: 78px;
    line-height: 15px;
    overflow: hidden;
    margin-top: -18px;
    /* background-color: aqua; */

}
.place-card-info-more{
    text-align: center;
    color: rgb(7, 7, 7);
    background-color: orange;
    padding: 3px 15px;
    border-radius: 25px;
    font-size: 12px;
    font-weight: 500;
    font-family: poppins;
    text-decoration: none;
    margin-top: -4px;
    transition: .8s;

}




