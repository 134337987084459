.Contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* .Contact-main::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0, 0.6);
} */
.Contact-main-title{
    font-size: 60px;
    font-weight: 600;
    font-family: Playfair Display;
    color: white;
    margin-top: 150px;
}
.Contact-main-route{
    display: flex;
    gap: 7px;
    margin-top: -32px;
}
.Contact-main-route-link{
    font-size: 18px;
    font-weight: 500;
    font-family: Poppins;
    color: white;
    text-decoration: none;
}
.Contact-main-route_link{
    font-size: 18px;
    font-weight: 500;
    font-family: Poppins;
    color: orange;
    text-decoration: none;
}
.Contact-main-media{
    display: flex;
    gap: 20px;
    margin-top: 58px;
}




.Contact-getintouch{
    display: flex;
    flex-direction: column;
    width: 1200px;
    font-family: Poppins;
}
.Contact-getintouch-title{
    font-size: 32px;
    font-weight: 500;
    margin-top: 100px;
    margin-bottom: 0;
}
.Contact-getintouch-description{
    font-size: 16px;
    font-weight: 100;
    margin-top: 10px;
}



.Contact-getintouch-contactrow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    width: 1200px;
    margin-top: 60px;
}
.Contact-getintouch-contactrow-input{
    display: flex;
    flex-direction: column;
    width: 707px;
    /* margin-top: 0; */
}
.Contact-getintouch-contactrow-input-row1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0;
}
.Contact-getintouch-contactrow-input-row1-name{
    display: flex;
    flex-direction: column;
}
.Contact-getintouch-contactrow-input-label{
    font-size: 18px;
    font-weight: 500;
}
.Contact-getintouch-contactrow-input-input{
    width: 319px;
    height: 30px;
    border: 2px solid rgb(151, 151, 152);
    border-radius: 5px;
    font-size: 15px;
    padding-left: 5px;
}
.Contact-getintouch-contactrow-input-row1-email{
    display: flex;
    flex-direction: column;
}
.Contact-getintouch-contactrow-input-row2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
}
.Contact-getintouch-contactrow-input-row2-number{
    display: flex;
    flex-direction: column;
}
.Contact-getintouch-contactrow-input-label{
    font-size: 18px;
    font-weight: 500;
}
.Contact-getintouch-contactrow-input-input{
    width: 319px;
    height: 30px;
    border: 2px solid rgb(151, 151, 152);
    border-radius: 5px;
}
.Contact-getintouch-contactrow-input-row2-country{
    display: flex;
    flex-direction: column;
}
.Contact-getintouch-contactrow-input-row3{
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}
.Contact-getintouch-contactrow-input-textarea{
    width: 701px;
    height: 132px;
    border: 2px solid rgb(151, 151, 152);
    border-radius: 5px;
    font-size: 15px;
    padding-left: 5px;
}
.Contact-getintouch-contactrow-input-btn{
    width: 227px;
    height: 43px;
    background-color: rgb(243, 149, 57);
    color: black;
    border: none;
    border-radius: 42px;
    font-size: 15px;
    font-weight: 500;
    margin-top: 50px;
    margin-bottom: 100px;
    cursor: pointer;
}



.Contact-getintouch-contactrow-contacts{
    display: flex;
    flex-direction: column;
    width: 388px;
    margin-top: 7px;
}
.Contact-getintouch-contactrow-contacts-row1{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    gap: 20px;
}
.Contact-getintouch-contactrow-contacts-row1-contact{
    width: 348px;
    margin-top: 0;
}
.Contact-getintouch-contactrow-contacts-row1-contact-address{
    width: 295px;
    margin-top: -7px;
    margin-bottom: 0;
}
.Contact-getintouch-contactrow-contacts-row2{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    gap: 20px;
    margin-top: 45px;
    margin-bottom: 0;
}
.Contact-getintouch-contactrow-contacts-row2-contact{
    width: 348px;
    margin-top: -7px;
}
.Contact-getintouch-contactrow-contacts-row2-contact-number1{
    width: 295px;
    margin-top: 0;
    margin-bottom: 0;
}
.Contact-getintouch-contactrow-contacts-row2-contact-number2{
    width: 295px;
    margin-top: 0;
    margin-bottom: 0;
}
.Contact-getintouch-contactrow-contacts-row3{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    gap: 20px;
    margin-top: 45px;
    margin-bottom: 0;
}
.Contact-getintouch-contactrow-contacts-row3-contact{
    width: 348px;
    margin-top: 0;
}
.Contact-getintouch-contactrow-contacts-row3-contact-email{
    width: 295px;
    margin-top: 0;
    margin-bottom: 0;
}
.Contact-getintouch-contactrow-contacts-row4{
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-top: 50px;
}