

.Tours{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.wrapper{
    width: 100%;
    height: 528px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Tour-over-layer-p{
    font-size: 60px;
    color: aliceblue;
    font-weight: 600;
    font-family: Playfair Display;
    margin-top: 150px;
}
.Tour-over-layer-route-div{
    display: flex;
    align-items: center;
    gap: 7px;
    margin-top: -50px;
}
.Tour-over-layer-route{
    font-size: 18px;
    font-weight: 500;
    font-family: poppins;
    color: white;
    text-decoration: none;
}
.Tour-over-layer_route{
    font-size: 18px;
    font-weight: 500;
    font-family: poppins;
    color: orange;
}
.Tour-over-layer-media-div{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 40px;
}







.Tour-description{
    text-align: justify;
    margin: 45px auto 35px auto;
    width: 1200px;
}

.button-div{
    border-top: 1px solid rgb(60, 60, 60);
    border-bottom: 1px solid rgb(60, 60, 60);
    height: 50px;
    margin: 0px 100px 20px 100px;
    display: flex;
    justify-content: center;
    gap: 100px;
    align-items: center;
    width: 1200px;
}
.Tour_button{
    background-color: rgba(0, 0, 73, 0);
    padding: 9px 20px;
    border-radius: 25px;
    color: rgb(0, 0, 0);
    font-weight: 500;
    text-decoration: none;
}
.Tour_button-active{
    background-color: rgb(0, 32, 73);
    padding: 9px 20px;
    border-radius: 25px;
    color: aliceblue;
    font-weight: 500;
    text-decoration: none;
}


.tours-card-container-div{
    /* background-color: aquamarine; */


    display: flex;
    justify-content: center;

}
.tours-card-container-div-sub{
    /* background-color: aquamarine; */

    width: 1200px;
    display:flex ;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;

    justify-content: left;
    margin-bottom: 35px;
}