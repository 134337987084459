.daytour-preview{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.daytour-preview-hero{
    height: 424px;
    width: 1200px;


}
.daytour-preview-hero-sub{
    display: flex;
    flex-direction: column;
    margin-top: -310px;
}
.daytour-hero-img{
    width: 1200px;
    height: 424px;
    background-size: cover;

}

.daytour-preview-hero-title{
    font-size: 54px;
    font-family: Playfair Display;
    font-weight: 600;
    color: white;
    text-align: center;
    text-transform: uppercase;
    background-color: rgba(0, 0, 0, 0.496);
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.daytour-preview-hero-route-div{
    display: flex;
    justify-content: center;
    margin-top: -50px;
    
}
.daytour-preview-hero-route-1{
    font-size: 18px;
    font-weight: 500;
    color: white;
    font-family: poppins;
    text-decoration: none;
}
.active-route{
    color: orange;
    }
.daytour-preview-hero-meadia-div{
    display: flex;
    gap: 20px;
    margin-top: -65px;
}
.daytour-preview-hero-meadia-div-media{
    display: flex;
    align-items: center;
    gap: 5px;
}
.day-tour-meadia-media-img{
    width: 24px;
    
}
.day-tour-meadia-media-link{
    font-size: 15px;
    font-weight: 500;
    color: white;
    
}






.daytour-preview-top{
    display: flex;
    justify-content: space-between;
    width: 1200px;
    margin: 50px auto 10px auto;
    /* background-color: yellowgreen; */
    position:relative;
}
.daytour-preview-top-left{
    /* background-color: aqua; */
    width: 389px;



}
.daytour-preview-top-left-price-div{
    display: flex;
    align-items: center;
    gap: 5px;
    

}
.daytour-preview-top-left-p1{
    font-size: 20px;
    font-weight: 400;
    font-family: poppins;

}
.daytour-preview-top-left-p3{
    font-size: 20px;
    font-weight: 400;
    font-family: poppins;
    text-decoration: line-through;
}
.daytour-preview-top-left-p2{
    font-size: 10px;
    font-weight: 400;
    font-family: poppins;
    
}
.daytour-preview-top-left-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: rgb(255, 85, 0); */
    height: 50px;

}
.daytour-preview-top-left-info-sub{
    display: flex;
    align-items: center;
    /* background-color: yellowgreen; */
    height: 30px;
    gap: 20px;
    
}


.daytour-preview-top-left-code{
    display: flex;
    align-items: center;
    gap: 6px;
    /* background-color: yellowgreen; */
}
.daytour-preview-top-left-code-p1{
    font-size: 20px;
    font-weight: 400;
    color: rgb(253, 37, 37);
    font-family: poppins;
}
.daytour-preview-top-left-code-p2{
    font-size: 20px;
    font-weight: 400;
    color: rgb(253, 37, 37);
    font-family: poppins;
    text-decoration: line-through;


}
.daytour-preview-top-left-code-p3{
    font-size: 20px;
    font-weight: 600;
    color: rgb(253, 37, 37);
    font-family: poppins;
}
.daytour-preview-top-left-code-p4{
    font-size: 10px;
    font-weight: 400;
    font-family: poppins;
    color: rgb(253, 37, 37);
}
.daytour-preview-top-left-line{
    border-bottom: 2px solid rgba(0, 0, 0, 0.245);
    width: 100%;


}
.daytour-preview-top-left-book{
    background-color:var(--orange);
    padding: 10px 25px;
    font-size: 20px;
    font-weight: 500;
    border-radius: 10px;
    border: none;
    color: white;
    margin-top: 20px;
   
}
.daytour-preview-top-right{
   
    width: 792px;
    margin: auto 0px 5px 0px;
       
}
.daytour-preview-top-right-img-div{

    width: 394px;
    height: 300px;
   

}
.daytour-preview-top-right-img{
    width: 392px;
    height: 300px;
    margin: 5px;
    display: flex;


}






.daytour-center-line{
    border-bottom: 2px solid rgba(0, 0, 0, 0.245);
    width: 1200px;

}
.daytour-center-info{
    margin: 10px 0px 10px 0px;
    width: 1200px;
    text-align: justify;
    font-size: 15px;
    font-weight: 400;
    /* background-color: yellowgreen; */
}







.daytour-preview-center{
    display: flex;
    justify-content: space-between;
    width: 1200px;
}
.daytour-preview-center-left{
    width: 550px;
    height: 409px;
    background-color: aqua;
}
.daytour-preview-center-map-container{
    width: 550px;
    height: 409px;
}
.daytour-preview-center-right{
    width: 606px;
    height: 411px;
    /* background-color: yellowgreen; */
    overflow-y: scroll;
    margin-left: 30px;
}
.daytour-preview-center-right-place-div{
   height: 24px;
    /* background-color: blue; */
    margin-top: -19px;
}
.daytour-preview-center-right-place-div-place-start{
    /* background-color: antiquewhite; */
    /* height: 10px; */
    text-align: justify;
    margin-right: 30px;
    font-size: 15px;
    
}
.daytour-preview-center-right-place-div-place{
    /* background-color: violet; */
    font-size: 15px;
    text-align: justify;
    
}
.daytour-preview-center-right--place-b{
    /* background-color: yellowgreen; */
    text-transform: uppercase;
    font-size: 14px;
    color: var(--blue1);
}
.daytour-preview-center-right--place{
    text-align: justify;
    margin-right: 30px;

}
.daytour-preview-center-right-place-description{
    text-align: justify;
    margin-right: 30px;
}









.daytour-preview-bottom{

}
.daytour-preview-bottom-btn-div{
    border-bottom: 2px solid rgba(0, 0, 0, 0.245);
    border-top: 2px solid rgba(0, 0, 0, 0.245);
    width: 1200px;
    height: 50px;
    margin: 35px auto 17px auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-transform: uppercase;
}
.daytour-preview-bottom-btn-active{
    background-color: rgb(0, 0, 102);
    padding: 7px 30px;
    border-radius: 25px;
    color: aliceblue;
    margin: 5px auto;
}
.daytour-preview-bottom-btn-deactive{
    margin: 5px auto;
    padding: 10px 30px;
    border-radius: 25px;
}
.daytour-preview-bottom-info-div{
    /* background-color: aqua; */
    /* height: 500px; */
}



.daytour-preview-bottom-info-1{
    display: flex;
    justify-content: space-between;
    gap: 50px;
    margin-top: 35px;
    margin-bottom: 35px;
    height: 385px;
    margin-top: -10px;
}
.daytour-preview-bottom-info-1-left{
    width: 50%;
}
.daytour-preview-bottom-info-1-left-title{
    font-size: 15px;
    font-weight: 600;
}
.daytour-preview-bottom-info-1-right{
    width: 50%;
}
.daytour-preview-bottom-info-1-right-title{
    font-size: 15px;
    font-weight: 600;
}
.daytour-preview-bottom-info-p1{
    font-size: 15px;
    margin: 7px auto;
}



.daytour-preview-bottom-info-2{
    width: 100%;
    height: 385px;

    margin-bottom: 35px;

   
}
.daytour-preview-bottom-info-2-map{
    width:1200px;
    height: 378px;
}
.daytour-preview-bottom-info-3{
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    gap: 8px;
    margin: 10px auto 30px auto ;
    height: 400px;
}


.hide{
    display: none;
}
/* pop up */
.day-tour-popup-1{
    
    background-color: rgba(255, 255, 255, 0.701);
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
}  
.day-tour-popup-1-main{
    border: 2px solid black;
    padding: 30px 50px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}   
.day-tour-popup-1-main-title{
    font-size: 24px;
    font-weight: 400;
    font-family: poppins;
}  
.day-tour-popup-1-main-form{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.day-tour-popup-1-main-form-label{
    font-size: 20px;
    font-weight: 400;
    font-family: poppins;
}
.day-tour-popup-1-main-form-input{
    width: 275px;
    height: 30px;
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, 0.692);
    text-align: center;
}
.day-tour-popup-1-main-button-div{
    display: flex;
    gap: 40px;
    margin: 10px auto;
    margin-top: 20px;
}
.day-tour-popup-1-main-ok{
    background-color: orange;
    padding: 10px 30px;
    border-radius: 10px;
    color: aliceblue;
}
.day-tour-popup-1-main-cancel{
    background-color: rgb(25, 40, 62);
    padding: 10px 30px;
    border-radius: 10px;
    color: aliceblue;
}









  

  

