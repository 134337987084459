

.admin-login{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-login-sub{
    border: 1px solid black;
    padding: 50px;
}

.admin-login-form{
    display: flex;
    flex-direction: column;
    margin: 20px auto;
}
.admin-login-form-label{

}
.admin-login-form-input{

}


.admin-login-form-button{
    
}