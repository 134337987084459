.profile-dashboard{
    /* display: none; */
    margin-left: 10px;
}
.profile-dashboard-btn-div{
    display: flex;
}
.profile-dashboard-btn-active{
    background-color: var(--blue);
    padding: 10px 30px;
    margin: 10px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 400;
    font-family: poppins;
    color: white;
    border: none;
}
.profile-dashboard-btn-deactive{

    padding: 10px 30px;
    margin: 10px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 400;
    font-family: poppins;
    border: none;
    &:hover{
        background-color: var(--blue);
        color: white;
        transition: .4s;
    }
}
.profile-dashboard-title{
    font-size: 24px;
    font-weight: 400;
    font-family: poppins;
}
.profile-dashboard-line-1{
    border-bottom: 1px solid rgba(185, 185, 186, 1);
    width: 90%;
}

/* .profile-right-tours-div{
    
} */



.profile-right-tours-heder-div{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    margin: 20px;
}
.profile-right-tours-heder-line{
    border-bottom: 2px solid rgba(185, 185, 186, 1);
    width: 100%;
}
.profile-right-tours-heder{
    color:rgba(185, 185, 186, 1); ;
}


.profile-right-tours-div{
    margin: 10px 10px 10px 10px;
    display: flex;
    justify-content: center;
    column-gap: 10px;
    flex-wrap: wrap;
}
.profile-right-tours-div-sub{
    margin: 10px 10px 10px 10px;
    display: flex;
    justify-content: left;
    column-gap: 10px;

    flex-wrap: wrap;
}
.profile-right-tours-no-p{
    text-align: center;
    color:rgb(185, 185, 186);
    font-weight: 600;
}




.profile-right-day-tours-heder-div{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    margin: 20px;
}
.profile-right-day-tours-heder-line{
    border-bottom: 2px solid rgba(185, 185, 186, 1);
    width: 100%;
}
.profile-right-day-tours-heder{
    color:rgba(185, 185, 186, 1) ;
    width: 149px;
}
.profile-right-day-tours-div{
    margin: 10px 10px 10px 10px;
    display: flex;
    justify-content: center;
    column-gap: 10px;
    flex-wrap: wrap;
}
.profile-right-day-tours-div-sub{
    margin: 10px;
    display: flex;
    justify-content: right;
    column-gap: 10px;
    flex-wrap: wrap;

}
.profile-right-day-tours-heder-not-p{
    text-align: center;
    color:rgb(185, 185, 186);
    font-weight: 600;
}






.profile-dashboard-tour{
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    margin: 10px;
}

.profile-dashboard-day-tour{
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    margin: 10px;
}
.profile-dashboard-day-tour-no-p{
    text-align: center;
    color:rgb(185, 185, 186);
    font-weight: 600;
}