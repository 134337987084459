
.popular-destinations{
 width: 100%;
 display: flex;
 flex-direction: column;
 align-items: center;
}

.wrapper{
    width: 100%;
    height: 528px;
    display: flex;
    justify-content: center;
    align-items: center;
  
}

.popular-destinations-main-title{
    font-size: 60px;
    font-weight: 600;
    font-family: Playfair Display;
    color: white;
    margin-top: 150px;
}
.popular-destinations-main-route{
    display: flex;
    gap: 7px;
    margin-top: -32px;
}
.popular-destinations-main-route-link{
    font-size: 18px;
    font-weight:500;
    font-family: Poppins;
    color: white;
}
.popular-destinations-main-route_link{
    font-size: 18px;
    font-weight:500;
    font-family: Poppins;
    color: orange;
}


.popular-destinations-main-media{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 58px

}











.popular-destinations-info{
    width: 1200px;
    text-align: justify;
    margin: 45px auto 35px auto;
}

.popular-destinations-places{
    max-width:  1192px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    gap: 5px;
    margin-bottom: 45px;
}
.popular-destinations-more{
    background-color: orange;
    padding: 10px 40px;
    border-radius: 25px;
    font-size: 15px;
    font-weight: 500;
    font-family: poppins;
    margin: 0px auto 35px auto;
    text-decoration: none;
    color: black;
}