.homeCarouselCard-main{
    width: 256px;
    height: 354px;
    /* position: relative; */
}
.homeCarouselCard{
    width: 251px;
    height: 350px;
    background-color: aquamarine;
    overflow: hidden;
    /* margin: 5px 5px; */
    border-radius: 20px;
    overflow: hidden;

}
.homeCarouselCard__img{
    /* width: 300px; */
    width: 100%;
    height: 100%;
}
.homeCarouselCard__content{
    background-color: rgba(0, 0, 0, 0.496);
    position: absolute;
    bottom: 4px;
    height: 158px;
    width: 251px;
    display: flex;
    justify-content: center;
    border-radius:0px 0px 20px 20px;
}

.homeCarouselCard__content-sub{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.homeCarousel_title{

    font-size: 24px;
    font-weight: 500;
    font-family: latio;
    color: azure;
    text-align: center;
    width: 240px;
    height: 50px;
    overflow: hidden;
    margin-top: 5px;
    /* background-color: aqua; */
}
.homeCarousel-description{
    color: azure;
    font-size: 13px;
    font-weight: 400;
    text-align: justify;
    width: 220px;
    height: 65px;
    line-height: 13px;
    overflow: hidden;
    margin-top: -38px;
}
.homeCarousel-see_more{
 text-align: center;
 color: rgb(7, 7, 7);
 background-color: orange;
 padding: 3px 15px;
 border-radius: 25px;
 font-size: 12px;
 font-weight: 500;
 font-family: poppins;
 text-decoration: none;
}