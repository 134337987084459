.HomeDayTour{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
}
.HomeDayTour-main{
    width: 1276px;
    height: 662;
    /* background-color: yellow; */
    display: flex;
    justify-content: space-between;
    align-items:flex-end;
}


















.HomeDayTour-carousel-info{
    background-color: rgb(255, 255, 255);
    width: 618px;
    height: 662px;
    box-shadow: -7px -9px 16px 0px rgba(29, 29, 31, 0.1);
    padding: 20px 50px 20px 100px;

}
.HomeDayTour-carousel-info-h1{
    font-size: 35px;
    font-weight: 600;
    margin-top: 10px;
    color: black;
}
.HomeDayTour-link{
    text-decoration: none;
    color: black;
}


.HomeDayTour-carousel-info-p1{
    font-size: 32px;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: 20px;
    transition: 1s;
    /* background-color: rgb(162, 255, 0); */
    max-width: 340px;
    overflow: hidden;
    text-decoration: none;
    color: var(--blue1);
}
.HomeDayTour-carousel-info-p2{
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    transition: 1s;
    /* background-color: rgb(0, 106, 255); */
    height: 260px;
    overflow: hidden;
    text-decoration: none;
    margin-top: -15px;
    text-align: justify;
}

.HomeDayTour-info-div-1{
    display: flex;
    flex-direction: column;
    margin-top: -20px;
}
.HomeDayTour-info-sub-div-3{
    display: flex;
    margin-bottom: 20px;
    /* background-color: yellowgreen; */

}
.HomeDayTour-info-sub-div-1{
    display: flex;
    /* background-color: yellowgreen; */

}
.HomeDayTour-info-sub-div-2{
    display: flex;
    margin-top: 40px;
    /* background-color: yellowgreen; */

}
.HomeDayTour-info-p1{
    font-size: 25px;
    font-weight: 400;
    margin: 0px;
    font-family: poppins;
}
.HomeDayTour-info-p2{
    font-size: 15px;
    font-weight: 400;
    margin: 0px;
    font-family: poppins;
}
.HomeDayTour-info-p3{
    font-size: 25px;
    font-weight: 400;
    margin: 0px;
    font-family: poppins;
    color: red;
    font-weight: 600;
}
.HomeDayTour-info-p4{
    font-size: 25px;
    font-weight: 400;
    margin: 0px;
    font-family: poppins;
    font-weight: 600;
}

.home-daytour-reacd-more{
    margin-top: 40px;
    margin-left: -330px;


    font-size: 17px;
    font-weight: 500;
    background-color: orange;
    padding: 8px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    color: black;
    text-decoration: none;
    color: white;
}








.HomeDayTour-carousel-info-price-div{
    display: flex;

}
.HomeDayTour-carousel-price-p, .HomeDayTour-carousel-price{
    font-size: 26px;
    font-weight: 600;
}
.home-daytour-reacd-more-div{
    width: 100%;
    display: flex;
    justify-content: center;
}
.HomeDayTour-carousel-readmore{
    font-size: 15px;
    font-weight: 500;
    background-color: orange;
    padding: 10px 30px;
    border-radius: 25px;
    text-decoration: none;
    color: black;
    transition: 1s;
   
}

.HomeDayTour-arrow-right{
    width: 48px;
    height: 48px;

}
.HomeDayTour-carousel-forward-btn{
    width: 48px;
    height: 48px;
    background-color: rgba(87, 87, 87, 0.445);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-top: 300px;
    margin-left: 650px;
    border: none;

}
.HomeDayTour-arrow-left{
    width: 48px;
    height: 48px;

}
.HomeDayTour-carousel-backward-btn{
    width: 48px;
    height: 48px;
    background-color: rgba(87, 87, 87, 0.445);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    margin-left: -650px;
    margin-top:300px;
    border: none;
    
}


.DayTourCarousel-img{
    width: 658px;
    height: 602px;
}



.start-animation {
    animation: slide-up 1s ease-in-out;
  }
  
@keyframes slide-up {
    0% {
        transform: translateY(200px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
    }
}