
.AboutTeamCarousel{
    width: 100%;
    display: flex;
    justify-content: center;
}

.AboutTeamCarousel-right-img{
    margin-left: -20px;
    position: relative;
}
.AboutTeamCarousel-right{
    background-color: transparent;
    border: none;
}

.AboutTeamCarousel-left-img{
    margin-right: -20px;
    position: relative;
    z-index: 1;
}
.AboutTeamCarousel-left{
    background-color: transparent;
    border: none;
}